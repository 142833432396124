<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <modal-duplicate-confirmation
      ref="duplicateConfirmationModal"
      @onContinue="onDuplicateConfirmationContinue"
    ></modal-duplicate-confirmation>
    <modal-delete-personalise-report
      ref="deletePersonaliseReportModal"
      @onDelete="onConfirmDeletePersonaliseReport"
      :personalisedReportTitle="formData.information.title"
    ></modal-delete-personalise-report>
    <modal-confirm-delete-pr-button ref="confirmDeletePrButtonModal"></modal-confirm-delete-pr-button>
    <form class="md-layout lims-form" @submit.prevent="handleSubmit()">
      <!-- Begin information block -->
      <lims-block>
        <h4 class="title" slot="blockTitle">{{ $t('pages/personalised-report/form/information/blockTitle') }}</h4>
        <div slot="blockContent">
          <md-button
            @click="onEdit"
            class="md-button md-primary lims-form-button md-theme-default edit-btn"
            v-if="isViewMode"
          >
            {{ $t('global/button/button.edit') }}
          </md-button>
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-33 md-small-size-50">
              <lims-field
                :model="formData.information"
                :schema="personalisedReportSchema.information"
                field="pathologistId"
              >
                <lims-single-select
                  slot="field"
                  :placeholder="
                    $t('entities/personalised-report/form/others/pathologist/fields/selectedOption/placeholder')
                  "
                  :options="pathologistOptions"
                  reduceKey="id"
                  labelKey="text"
                  :disabled="isViewMode || !isAdminView || IS_DISABLE"
                  v-model="formData.information.pathologistId"
                >
                  <template #option="{ text }">{{ text }}</template>
                  <template #selected-option="{ text }">{{ text }}</template>
                </lims-single-select>
              </lims-field>
            </div>
          </div>
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-66 md-small-size-50">
              <lims-field :model="formData.information" :schema="personalisedReportSchema.information" field="title">
                <md-input
                  slot="field"
                  v-model="formData.information.title"
                  :disabled="isViewMode || IS_DISABLE"
                  type="text"
                  maxlength="250"
                  tabenable="yes"
                ></md-input>
              </lims-field>
            </div>
            <div class="md-layout-item md-size-33 md-small-size-50">
              <lims-field :model="formData.information" :schema="personalisedReportSchema.information" field="shortcut">
                <lims-tooltip
                  slot="label-info"
                  :content="$t('pages/personalised-report/form/hintShortcut')"
                ></lims-tooltip>
                <md-input
                  slot="field"
                  v-model="formData.information.shortcut"
                  :disabled="isViewMode || IS_DISABLE"
                  type="text"
                  maxlength="15"
                  tabenable="yes"
                ></md-input>
              </lims-field>
            </div>
          </div>
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-66 md-small-size-50">
              <label>{{ $t('entities/personalised-report/form/information/status') }}</label>
              <p v-if="IS_ENABLE">
                {{ $t('pages/personalised-report/form/information/status.enabled') }}
              </p>
              <p v-if="IS_DISABLE">
                {{ $t('pages/personalised-report/form/information/status.disabled') }}
              </p>
            </div>
          </div>
        </div>
      </lims-block>
      <!-- End information block -->
      <!-- Begin report block -->
      <lims-block>
        <h4 class="title" slot="blockTitle">{{ $t('pages/personalised-report/form/report/blockTitle') }}</h4>
        <div slot="blockContent">
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-33 md-small-size-100">
              <lims-field :model="formData.report" :schema="personalisedReportSchema.report" field="snomedM">
                <lims-single-select
                  slot="field"
                  :placeholder="
                    $t('entities/personalised-report/form/others/snomedM/fields/selectedOption/placeholder')
                  "
                  :options="snomedMOption"
                  :disabled="isViewMode || IS_DISABLE"
                  reduceKey="fieldItemId"
                  labelKey="fieldItemName"
                  v-model="formData.report.snomedM"
                >
                  <template #option="{ fieldItemName }">{{ fieldItemName }}</template>
                  <template #selected-option="{ fieldItemName }">{{ fieldItemName }}</template>
                </lims-single-select>
              </lims-field>
            </div>
            <div class="md-layout-item md-size-33 md-small-size-100">
              <lims-field :model="formData.report" :schema="personalisedReportSchema.report" field="snomedP">
                <lims-single-select
                  slot="field"
                  :placeholder="
                    $t('entities/personalised-report/form/others/snomedP/fields/selectedOption/placeholder')
                  "
                  :options="snomedPOption"
                  :disabled="isViewMode || IS_DISABLE"
                  reduceKey="fieldItemId"
                  labelKey="fieldItemName"
                  v-model="formData.report.snomedP"
                >
                  <template #option="{ fieldItemName }">{{ fieldItemName }}</template>
                  <template #selected-option="{ fieldItemName }">{{ fieldItemName }}</template>
                </lims-single-select>
              </lims-field>
            </div>
            <div class="md-layout-item md-size-33 md-small-size-100">
              <lims-field :model="formData.report" :schema="personalisedReportSchema.report" field="snomedT">
                <lims-single-select
                  slot="field"
                  :placeholder="
                    $t('entities/personalised-report/form/others/snomedT/fields/selectedOption/placeholder')
                  "
                  :options="snomedTOption"
                  :disabled="isViewMode || IS_DISABLE"
                  reduceKey="fieldItemId"
                  labelKey="fieldItemName"
                  v-model="formData.report.snomedT"
                >
                  <template #option="{ fieldItemName }">{{ fieldItemName }}</template>
                  <template #selected-option="{ fieldItemName }">{{ fieldItemName }}</template>
                </lims-single-select>
              </lims-field>
            </div>
          </div>
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-50 md-small-size-100">
              <lims-field
                :model="formData.report"
                :schema="personalisedReportSchema.report"
                field="microscopy"
                class="textarea-field"
              >
                <lims-tooltip
                  slot="label-info"
                  :content="$t('pages/personalised-report/form/hintMicroscopy')"
                ></lims-tooltip>
                <md-textarea
                  slot="field"
                  v-model="formData.report.microscopy"
                  :disabled="isViewMode || IS_DISABLE"
                  type="text"
                  tabenable="yes"
                ></md-textarea>
              </lims-field>
            </div>
            <div class="md-layout-item md-size-50 md-small-size-100">
              <lims-field
                :model="formData.report"
                :schema="personalisedReportSchema.report"
                field="commentDiagnosis"
                class="textarea-field"
              >
                <lims-tooltip
                  slot="label-info"
                  :content="$t('pages/personalised-report/form/hintCommentDiagnosis')"
                ></lims-tooltip>
                <md-textarea
                  slot="field"
                  v-model="formData.report.commentDiagnosis"
                  :disabled="isViewMode || IS_DISABLE"
                  type="text"
                  tabenable="yes"
                ></md-textarea>
              </lims-field>
            </div>
          </div>
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-100 md-small-size-100">
              <h5>{{ $t('entities/personalised-report/form/others/version/personalisedReportVersion') }}</h5>
              <hr />
            </div>
            <div class="md-layout-item md-size-50 md-small-size-100">
              <lims-field :model="formData.report" :schema="personalisedReportSchema.report" field="version">
                <lims-single-select
                  slot="field"
                  :placeholder="$t('entities/personalised-report/form/others/version/placeholder')"
                  :options="versionOption"
                  :disabled="isViewMode || IS_DISABLE"
                  reduceKey="casePersonalizedReportId"
                  labelKey="clinicName"
                  v-model="casePersonalizedReportId"
                >
                  <template #option="{ clinicName }">{{ clinicName }}</template>
                  <template #selected-option="{ clinicName }">{{ clinicName }}</template>
                </lims-single-select>
              </lims-field>
            </div>
          </div>
        </div>
      </lims-block>
      <!-- End report block -->
      <!-- Begin action block -->
      <div class="md-layout lims-form-row">
        <div class="md-layout-item md-size-100 md-small-size-100 lims-form-actions">
          <md-button @click="onCancel" class="md-button lims-form-button md-theme-default">
            {{ $t('global/button/button.cancel') }}
          </md-button>
          <md-button
            @click="onEdit"
            class="md-button md-primary lims-form-button md-theme-default"
            v-if="isViewMode && $isAuthorized(['Case_PersonalizedReports_Update'])"
          >
            {{ $t('global/button/button.edit') }}
          </md-button>
          <md-button
            class="md-primary lims-form-button"
            @click="onDuplicate"
            v-if="isEditMode && IS_ENABLE && $isAuthorized(['Case_PersonalizedReports_Create'])"
            >{{ $t('global/button/button.duplicate') }}</md-button
          >
          <md-button
            class="md-primary lims-form-button"
            @click="onSave"
            v-if="isEditMode && IS_ENABLE && $isAuthorized(['Case_PersonalizedReports_Update'])"
            >{{ $t('global/button/button.save') }}</md-button
          >
          <md-button
            class="md-primary lims-form-button"
            @click="onCreate"
            v-if="isAddMode && $isAuthorized(['Case_PersonalizedReports_Create'])"
            >{{ $t('global/button/button.create') }}</md-button
          >
          <md-button
            class="md-primary lims-form-button"
            @click="onEnable"
            v-if="isEditMode && IS_DISABLE && $isAuthorized(['Case_PersonalizedReports_Update'])"
            >{{ $t('global/button/button.enable') }}</md-button
          >
          <md-button
            class="md-danger lims-form-button"
            @click="onDelete"
            v-if="isEditMode && IS_DISABLE && $isAuthorized(['Case_PersonalizedReports_Delete'])"
            >{{ $t('global/button/button.delete') }}</md-button
          >
          <md-button
            class="md-danger lims-form-button"
            @click="onDisable"
            v-if="isEditMode && IS_ENABLE && $isAuthorized(['Case_PersonalizedReports_Update'])"
            >{{ $t('global/button/button.disable') }}</md-button
          >
        </div>
      </div>
      <!-- End action block -->
    </form>
  </ValidationObserver>
</template>

<script>
import { FormMixins, TabMixins } from '@/core/mixins';
import { APP_ROUTES, FORM_MODES, PERSONALISED_REPORT } from '@/core/constants';
import { getPersonalisedReportSchema } from '@/schemas/personalised-report.schema';
import { DropdownService, PersonalizedReportService } from '@/services';
import { DROPDOWN_SHORT_NAME } from '@/core/constants';
import LimsBlock from '@/components/Lims/LimsBlock.vue';
import ModalDuplicateConfirmation from '@/components/Lims/modals/ModalDuplicateConfirmation';
import ModalDeletePersonaliseReport from '@/components/Lims/modals/ModalDeletePersonaliseReport';
import { mapGetters, mapActions } from 'vuex';
import ModalConfirmDeletePrButton from '@/components/Lims/modals/ModalConfirmDeletePrButton';

export default {
  components: { ModalConfirmDeletePrButton, ModalDeletePersonaliseReport, ModalDuplicateConfirmation, LimsBlock },
  name: 'PersonalisedReportForm',
  mixins: [FormMixins, TabMixins],
  props: {
    formMode: {
      type: Number,
      require: true,
      validator: function (value) {
        return Object.values(FORM_MODES).indexOf(value) !== -1;
      },
    },
    personalisedReportResource: {
      type: Object,
      require: false,
    },
  },
  data() {
    return {
      pathologistOptions: [],
      snomedMOption: [],
      snomedPOption: [],
      snomedTOption: [],
      versionOption: [],
      versionData: [],
      casePersonalizedReportId: null,
      formData: {
        information: {
          title: '',
          shortcut: '',
          pathologistId: null,
          status: PERSONALISED_REPORT.Enabled,
        },
        report: {
          microscopy: '',
          commentDiagnosis: '',
          snomedM: null,
          snomedP: null,
          snomedT: null,
        },
      },
      isSubmitted: false,
    };
  },
  computed: {
    ...mapGetters('auth', ['userId']),
    ...mapGetters('personalisedReport', ['getPersonalisedReportData']),
    ...mapActions('personalisedReport', ['copyPersonalisedReportData']),
    isAdminView() {
      const userType = this.$store.getters['auth/userType'];
      return userType === this.USER_TYPES().Administrator;
    },
    isPathologistView() {
      const userType = this.$store.getters['auth/userType'];
      return userType === this.USER_TYPES().Pathologist;
    },
    personalisedReportSchema: function () {
      return getPersonalisedReportSchema();
    },
    isAddMode() {
      return this.formMode === FORM_MODES.ADD;
    },
    isEditMode() {
      return this.formMode === FORM_MODES.EDIT;
    },
    isViewMode() {
      return this.formMode === FORM_MODES.VIEW;
    },
    IS_ENABLE() {
      return this.formData.information.status === PERSONALISED_REPORT.Enabled;
    },
    IS_DISABLE() {
      return this.formData.information.status === PERSONALISED_REPORT.Disabled;
    },
  },
  created() {
    this.fetchData();
  },
  watch: {
    async casePersonalizedReportId() {
      if (this.casePersonalizedReportId === null) {
        await this.fetchData();
      } else {
        this.versionData = await this.getPersonalReportData();
        this.formData = {
          ...this.formData,
          report: {
            commentDiagnosis: this.versionData.comment,
            microscopy: this.versionData.microscopy,
            snomedM: this.versionData.snomedMFieldItem?.fieldItemId,
            snomedP: this.versionData.snomedPFieldItem?.fieldItemId,
            snomedT: this.versionData.snomedTFieldItem?.fieldItemId,
          },
        };
      }
    },
  },
  methods: {
    async fetchData() {
      const dropdownOptions = await DropdownService.getDropdownByShortNames([
        DROPDOWN_SHORT_NAME.SNOMED_M,
        DROPDOWN_SHORT_NAME.SNOMED_P,
        DROPDOWN_SHORT_NAME.SNOMED_T,
        DROPDOWN_SHORT_NAME.PATHOLOGIST,
      ]);
      this.snomedMOption = dropdownOptions[DROPDOWN_SHORT_NAME.SNOMED_M];
      this.snomedPOption = dropdownOptions[DROPDOWN_SHORT_NAME.SNOMED_P];
      this.snomedTOption = dropdownOptions[DROPDOWN_SHORT_NAME.SNOMED_T];
      this.pathologistOptions = dropdownOptions[DROPDOWN_SHORT_NAME.PATHOLOGIST];
      if (this.isPathologistView) {
        this.pathologistOptions.forEach((pathologist) => {
          if (this.userId === pathologist.id) {
            this.formData.information.pathologistId = pathologist.id;
          }
        });
      }
      if (this.getPersonalisedReportData) {
        this.formData = {
          information: {
            pathologistId: this.getPersonalisedReportData.information?.pathologistId,
            title: this.getPersonalisedReportData.information?.title + ' COPY',
            shortcut: '',
            status: this.getPersonalisedReportData.information?.status,
          },
          report: {
            commentDiagnosis: this.getPersonalisedReportData.report?.commentDiagnosis,
            microscopy: this.getPersonalisedReportData.report?.microscopy,
            snomedM: this.getPersonalisedReportData.report?.snomedM,
            snomedP: this.getPersonalisedReportData.report?.snomedP,
            snomedT: this.getPersonalisedReportData.report?.snomedT,
          },
        };
        await this.$store.dispatch('personalisedReport/copyPersonalisedReportData', null);
      }
      if (this.isViewMode || this.isEditMode) {
        this.formData = {
          information: {
            pathologistId: this.personalisedReportResource.pathologistId,
            title: this.personalisedReportResource.title,
            shortcut: this.personalisedReportResource.shortcut,
            status: this.personalisedReportResource.status,
          },
          report: {
            commentDiagnosis: this.personalisedReportResource.comment,
            microscopy: this.personalisedReportResource.microscopy,
            snomedM: this.personalisedReportResource.snomedMFieldItem?.fieldItemId,
            snomedP: this.personalisedReportResource.snomedPFieldItem?.fieldItemId,
            snomedT: this.personalisedReportResource.snomedTFieldItem?.fieldItemId,
          },
        };
      }
      if (
        this.personalisedReportResource &&
        this.personalisedReportResource.versions &&
        this.personalisedReportResource.versions.length > 0
      ) {
        this.versionOption = this.personalisedReportResource.versions;
      }
      this.$setHeadingTitle(this.formData.information.title);
    },
    async getPersonalReportData() {
      const { data, error } = await PersonalizedReportService.getPersonalizedReport(this.casePersonalizedReportId);
      if (error) {
        this.$alertErrorServerSide(error);
      } else {
        if (data) {
          return data;
        }
      }
    },
    onCancel() {
      this.$router.push(APP_ROUTES.PERSONALISED_REPORT);
    },
    onEdit() {
      this.$router.push({
        path: APP_ROUTES.PERSONALISED_REPORT_EDIT + '/' + this.personalisedReportResource.casePersonalizedReportId,
      });
    },
    onDuplicate() {
      this.$refs.duplicateConfirmationModal.open();
    },
    onDuplicateConfirmationContinue() {
      this.$refs.duplicateConfirmationModal.close();
      const personalisedReportData = {
        ...this.formData,
      };
      this.$store.dispatch('personalisedReport/copyPersonalisedReportData', personalisedReportData);
      this.$router.push(APP_ROUTES.PERSONALISED_REPORT_ADD);
    },
    onSave() {
      this.$refs.form.validate().then(async (success) => {
        if (success) {
          try {
            const dataForm = this.personalisedReportData();
            let casePersonalizedReportId;
            if (this.casePersonalizedReportId) {
              casePersonalizedReportId = this.casePersonalizedReportId;
            } else {
              casePersonalizedReportId = this.personalisedReportResource.casePersonalizedReportId;
              dataForm.shortcut = this.formData.information.shortcut;
            }
            const { error } = await PersonalizedReportService.updatePersonalizedReport(
              casePersonalizedReportId,
              dataForm,
            );
            if (error) {
              if (error == 'IsExistShortcut') {
                this.$alertError(
                  this.$t('pages/personalised-report/form/alertError/IsExistShortcut', {
                    currentShort: this.formData.information.shortcut,
                  }),
                );
              } else {
                return this.$alertErrorServerSide(error);
              }
            } else {
              this.$router.push(APP_ROUTES.PERSONALISED_REPORT).then(() => {
                this.$alertSuccess(
                  this.$t('pages/personalised-report/form/alertSuccess/update', {
                    title: this.formData.information.title,
                  }),
                );
              });
            }
          } catch (errors) {
            this.$alertError(errors);
          }
        } else {
          this.$alertError(this.$t(`global/errors/message`));
        }
      });
    },
    onCreate() {
      this.$refs.form.validate().then(async (success) => {
        if (success) {
          try {
            const dataForm = this.personalisedReportData();
            dataForm.shortcut = this.formData.information.shortcut;
            const { data, error } = await PersonalizedReportService.createPersonalizedReport(dataForm);
            if (error) {
              if (error == 'IsExistShortcut') {
                this.$alertError(
                  this.$t('pages/personalised-report/form/alertError/IsExistShortcut', {
                    currentShort: this.formData.information.shortcut,
                  }),
                );
              } else {
                return this.$alertErrorServerSide(error);
              }
            } else {
              if (data) {
                this.$router.push(APP_ROUTES.PERSONALISED_REPORT).then(() => {
                  this.$alertSuccess(
                    this.$t('pages/personalised-report/form/alertSuccess/create', {
                      title: this.formData.information.title,
                    }),
                  );
                });
              }
            }
          } catch (errors) {
            this.$alertError(errors);
          }
        } else {
          this.$alertError(this.$t(`global/errors/message`));
        }
      });
    },
    personalisedReportData() {
      const snomedMFieldId = this.getFieldIdFromSnomedList(this.snomedMOption);
      const snomedPFieldId = this.getFieldIdFromSnomedList(this.snomedPOption);
      const snomedTFieldId = this.getFieldIdFromSnomedList(this.snomedTOption);
      const payload = {
        pathologistId: this.formData.information?.pathologistId,
        title: this.formData.information?.title,
        microscopy: this.formData.report?.microscopy,
        comment: this.formData.report?.commentDiagnosis,
      };
      if (this.formData.report.snomedM) {
        Reflect.set(payload, 'snomedMFieldItem', {
          fieldId: snomedMFieldId,
          fieldItemId: this.formData.report.snomedM,
        });
      }
      if (this.formData.report.snomedP) {
        Reflect.set(payload, 'snomedPFieldItem', {
          fieldId: snomedPFieldId,
          fieldItemId: this.formData.report.snomedP,
        });
      }
      if (this.formData.report.snomedT) {
        Reflect.set(payload, 'snomedTFieldItem', {
          fieldId: snomedTFieldId,
          fieldItemId: this.formData.report.snomedT,
        });
      }
      return payload;
    },
    getFieldIdFromSnomedList(snomedList) {
      if (snomedList.length > 0) {
        return snomedList[0].fieldId;
      }
    },
    onDelete() {
      if (this.personalisedReportResource.hasButtonAssigned) {
        this.$refs.confirmDeletePrButtonModal.open();
      } else {
        this.$refs.deletePersonaliseReportModal.open();
      }
    },
    async onDisable() {
      this.$refs.deletePersonaliseReportModal.close();
      const { data, error } = await PersonalizedReportService.disablePersonalizedReport(
        this.personalisedReportResource.casePersonalizedReportId,
      );
      if (error) {
        return this.$alertErrorServerSide(error);
      } else {
        if (data) {
          this.$router.push(APP_ROUTES.PERSONALISED_REPORT).then(() => {
            this.$alertSuccess(
              this.$t('pages/personalised-report/form/alertSuccess/disable', {
                title: this.formData.information.title,
              }),
            );
          });
        }
      }
    },
    async onEnable() {
      this.$refs.deletePersonaliseReportModal.close();
      const { data, error } = await PersonalizedReportService.enablePersonalizedReport(
        this.personalisedReportResource.casePersonalizedReportId,
      );
      if (error) {
        return this.$alertErrorServerSide(error);
      } else {
        if (data) {
          this.$router.push(APP_ROUTES.PERSONALISED_REPORT).then(() => {
            this.$alertSuccess(
              this.$t('pages/personalised-report/form/alertSuccess/enable', {
                title: this.formData.information.title,
              }),
            );
          });
        }
      }
    },
    async onConfirmDeletePersonaliseReport() {
      this.$refs.deletePersonaliseReportModal.close();
      const { data, error } = await PersonalizedReportService.deletePersonalizedReport(
        this.personalisedReportResource.casePersonalizedReportId,
      );
      if (error) {
        return this.$alertErrorServerSide(error);
      } else {
        if (data) {
          this.$router.push(APP_ROUTES.PERSONALISED_REPORT).then(() => {
            this.$alertSuccess(
              this.$t('pages/personalised-report/form/alertSuccess/delete', {
                title: this.formData.information.title,
              }),
            );
          });
        }
      }
    },
  },
};
</script>

<style lang="scss">
.abc {
  &:after {
    content: '';
    position: absolute;
    bottom: 14px;
    left: 0;
    height: 1px;
    width: 100%;
    background-color: #ddd;
  }
}
</style>
