import { cloneDeep } from 'lodash';

const InformationDTO = {
  specialtyId: 'required',
  title: 'required',
  shortcut: '',
  selectedSpecialtyId: 'required',
  pathologistId: 'required',
};
const reportDTO = {
  snomedM: '',
  snomedP: '',
  snomedT: '',
  version: '',
  microscopy: '',
  commentDiagnosis: '',
  buttonName: 'required',
  personalReport: 'required',
  buttonTitle: 'required',
  buttonSubTitle: '',
  buttonColor: 'required',
  buttonNote: '',
};

const PersonalisedReportSchema = {
  information: {
    entity: 'personalised-report/form/information',
    fields: {
      ...InformationDTO,
    },
  },
  report: {
    entity: 'personalised-report/form/report',
    fields: {
      ...reportDTO,
    },
  },
};

const preparePersonalisedReportSchema = (PersonalisedReportSchema = {}) => {
  return cloneDeep(PersonalisedReportSchema);
};

export const getPersonalisedReportSchema = () => {
  return preparePersonalisedReportSchema(PersonalisedReportSchema);
};
