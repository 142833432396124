<template>
  <md-dialog :md-close-on-esc="true" :md-active.sync="isVisible" @md-clicked-outside="close" class="lims-modal">
    <div class="dialog-icon-close" @click="close"><md-icon>close</md-icon></div>
    <md-dialog-title>{{ $t('components/lims/modals/ModalDeletePersonaliseReport.title') }}</md-dialog-title>
    <div class="dialog-content">
      <p class="text-center">{{ $t('components/lims/modals/ModalDeletePersonaliseReport.actionCannotBeUndone') }}</p>
      <p class="text-center">
        {{
          $t('components/lims/modals/ModalDeletePersonaliseReport.enterPassword', {
            title: personalisedReportTitle,
          })
        }}
      </p>
      <lims-field :model="formData" :schema="checkCodeSchema" field="passwordForCase">
        <md-input slot="field" v-model="formData.password" type="password"></md-input>
      </lims-field>
    </div>
    <md-dialog-actions>
      <md-button @click="onCancel" class="lims-form-button">
        {{ $t('global/button/button.cancel') }}
      </md-button>
      <md-button @click="onDelete" class="md-danger lims-form-button" :disabled="!formData.password">
        {{ $t('global/button/button.delete') }}
      </md-button>
    </md-dialog-actions>
  </md-dialog>
</template>

<script>
import modalMixins from './modal.mixins';
import { getCheckCodeSchema } from '@/schemas/check-code.schema';
export default {
  mixins: [modalMixins],
  props: {
    isWrongPassword: {
      type: Boolean,
      default: false,
    },
    status: {
      type: String,
    },
    personalisedReportTitle: {
      type: String,
    },
  },
  data() {
    return {
      formData: {
        password: null,
      },
    };
  },
  computed: {
    checkCodeSchema() {
      return getCheckCodeSchema(this.isWrongPassword);
    },
  },
  methods: {
    onCancel() {
      this.close();
    },
    onDelete() {
      this.$emit('onDelete', this.formData);
    },
  },
};
</script>
<style lang="scss"></style>
